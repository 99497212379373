import React from 'react';
import { useState } from "react";
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import '../styles/loginpage.css';
import Logo from '../images/logo.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';

const ForgetPasswordPage = () => {

    const [nomailError, setNoEmailError] = useState(false);
    const [notActiveError, setNotActiveError] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    var item ="";
    const history = useHistory();

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

     if (itemStr && item.expiry > now.getTime()) {
      history.push('/');
      window.location.reload(false);
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required.')
            .email('Email is invalid.'), 
    });
  
  
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema)
  });
  
      function onSubmit(data) {
        setNoEmailError(false);
        setSuccessMsg(false);

          try {
             const json = JSON.stringify({ email: data.email});
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://compsciedu.com/services/service1.svc/ForgotPassword', json, {headers}).then(response => {
                 const userData = response.data;
                
                 if(userData.ForgotPasswordResult.FirstName == "No Such Email."){
                   setNoEmailError(true);
                 }
                 else if(userData.ForgotPasswordResult.Email != undefined && userData.ForgotPasswordResult.IsActivated == true){
                   setSuccessMsg(true);
                  }
                  else if(userData.ForgotPasswordResult.Email != undefined && userData.ForgotPasswordResult.IsActivated == false){
                  setNotActiveError(true);
                  }
             });
         } catch (error) {
             console.log(error);
         } 
      }

    return (
        <>
          <MetaTags>
            <title>Reset Your Password - Test and Rank</title>
            <meta id="meta-description" name="description" 
             />
          </MetaTags>

            <Navbar />
            <div className='BoxContainer'>
              <div className='FormContainer'>
    
              <div>
        <img width="120px" height="120px" src={Logo} alt="Test and Rank logo" />
        </div>
    
        <div className='login-heading'>
          <h3>Forgot Password</h3>
        </div>
    
        <div className='sendmail'>
          <p>Enter the Email id you have chosen at the time of Sign Up.</p>
        </div>
    
            <div className="Login">
               <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
               
    <div class="form-group row login-form">
        
        <div class="col-sm-12">
          <input name="email" maxLength={60} {...register('email', { required: true })} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="Email"/>
          <div id="email-error" className="invalid-feedback">{errors.email?.message}</div>
        </div>
      </div>
     
      <div class="form-group row login-form">
        <div class="col-sm-10 login-btn-div">
          <button  type="submit" className="btn btn-primary login-btn btn-lg btn-block">Send Reset Link</button>
        </div>
      </div>
              </Form>   
              </div>
    
              {notActiveError ?<div className="invalid-user">Account associated with this Email is not verified. Please check your mail for the verification Link.</div> : ''}
    
              {nomailError ?<div className="invalid-user">There is no account associated with this Email.
              Please <Link to="/signup">Sign Up</Link> to get started.
              </div> : ''}
    
              {successMsg ?<div className="invalid-user">Mail sent successfully. Please check your mail for password reset link. </div> : ''}

              <div>
              <span className='login-text'>Don't have an account?  <Link to="/signup">Sign Up</Link></span>
              </div>
           
              </div>
            </div>
            </>
      );
};

export default ForgetPasswordPage;
