import React from 'react';
import '../styles/topcontainer.css';
import Logo from '../images/logo.png'

const Topcontainer = () => {
    return (
        <>
            <div className="mainContainer">
            <div className="bgFilter">
            <div class="container">

  <div className="row">
    <div className="col-md-3 UpperLayer">
    <img width="150px" height="150px" src={Logo} alt="Test and Rank logo" />
    </div>
    <div className="col-md-9 UpperLayer">
    <h3 className="SloganText">Practice unlimited Computer Science online tests and evaluate your performance.</h3>
    <div className="DescText">
        Welcome to the ultimate portal to test your Knowledge through hundreds of MCQ based online tests and quizes.
        Attempt an instant online test in topics like Networking, Software Engineering, Data Structures and Algorithms, Artificial Intelligence
        Cloud Computing, IT Fundamentals, C Programming, Formal Languages and Automata Theory and many more.
    </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-8 Features">
     <h3>Features:</h3>
     <div>
         <p>Attempt unlimited online mock Tests.</p>
         <p>See your score instantly after completeing the Test.</p>
         <p>Compare your score with other top performers and with overall score of all Aspirants.</p>
         <p>Category Wise Online Test are availabe for all computer science subjects.</p>
        
    </div>
    </div>
    <div class="col-lg-4 d-none d-lg-block">
    <button type="button" class="Catbtn btn">DBMS</button>
{/* <button type="button" class="Catbtn btn">Neural Networks</button> */}
<button type="button" class="Catbtn btn">JavaScript</button>
<button type="button" class="Catbtn btn">UNIX</button>
<button type="button" class="Catbtn btn">HTML</button>
<button type="button" class="Catbtn btn">Python</button>
<button type="button" class="Catbtn btn">Data Science</button>
{/* <button type="button" class="Catbtn btn">Operating System</button> */}
    </div>
    
  </div>
  </div>
            </div>
            </div>
        </>
    )
}

export default Topcontainer
