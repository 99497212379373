import React from 'react';
import { MetaTags } from 'react-meta-tags';
import "../styles/faqspage.css";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const FaqsPage = () => {
  return (
      <>
<MetaTags>
            <title>Frequently Asked Questions - Test and Rank</title>
            <meta id="meta-description" name="description" />          
</MetaTags>

      <Navbar />
     
    <div class="faq_area section_padding_130" id="faq">
    <div class="container">

    <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-lg-6">
                <div class="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <h3><span>Frequently </span> Asked Questions</h3>
                    <p>Top Questions about Test and Rank</p>
                    <div class="line"></div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion"> 
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingOne"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne"
      >
        Can I attempt a test multiple times?
      </button>
    </h2>
    <div
      id="flush-collapseOne"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingOne"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      A test can be attempted as many times as you want.
      </div>
    </div>
  </div>

  </div>
  </div>
  </div>
  </div>
            
            <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingTwo"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseTwo"
        aria-expanded="false"
        aria-controls="flush-collapseTwo"
      >
        Will I get my performance analysis and solutions after the test?
      </button>
    </h2>
    <div
      id="flush-collapseTwo"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingTwo"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      Yes, you will get a detailed analysis of your performance along with correct answers after 
      submitting the test.
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
  </div>
            
            <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                        <div class="card-header" id="headingThree"></div>
       <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseThree"
        aria-expanded="false"
        aria-controls="flush-collapseThree"
      >
        Can I download the online test with solutions after the test?
      </button>
    </h2>
    <div
      id="flush-collapseThree"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingThree"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      No, you cannot download the test; however, you can access and analyse your performance in 
      recently attempted tests by logging into your account.
      </div>
    </div>
  </div>
</div> 
</div>
</div> 
</div>
  
  <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingFour"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFour">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseFour"
        aria-expanded="false"
        aria-controls="flush-collapseFour"
      >
        When I re-attempt the test of same topic, are the questions same as previous test?

      </button>
    </h2>
    <div
      id="flush-collapseFour"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingFour"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      No, You will get unique set of questions every time you attempt a test. However, some questions 
      can repeat after many number of attempts. 
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
  </div>

  <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingFive"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFive">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseFive"
        aria-expanded="false"
        aria-controls="flush-collapseFive"
      >
        How my rank for a particulat test is calculated?

      </button>
    </h2>
    <div
      id="flush-collapseFive"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingFive"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      Your rank is calculated by comparing your score with other aspirants who attempt the 
      same test.
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
  </div>

  <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingSix"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSix">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseSix"
        aria-expanded="false"
        aria-controls="flush-collapseSix"
      >
        Why do I need to sign-up to take a test?

      </button>
    </h2>
    <div
      id="flush-collapseSix"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingSix"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      We require you to sign-up before attempting a online test because that helps us to provide you
      a better experience by storing your results and providing key points regarding your score improvement.
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
  </div>

  <div class="col-12 col-sm-10 col-lg-8">
                <div class="accordion faq-accordian" id="faqAccordion">
                    
                    <div class="card border-0 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                    <div class="card-header" id="headingSeven"></div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSeven">
      <button
        class="accordion-button collapsed"
        type="button"
        data-mdb-toggle="collapse"
        data-mdb-target="#flush-collapseSeven"
        aria-expanded="false"
        aria-controls="flush-collapseSeven"
      >
        What happens if my test is interrupted for any reason?

      </button>
    </h2>
    <div
      id="flush-collapseSeven"
      class="accordion-collapse collapse"
      aria-labelledby="flush-headingSeven"
      data-mdb-parent="#accordionFlushExample"
    >
      <div class="accordion-body">
      If you close the browser while test is in progress or you lost Internet connectivity, all 
      your progress will be lost and you will not be able to retrieve/resume the test.
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
  </div>
  </div>

<div class="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                    <i class="lni-emoji-sad"></i>
                    <p class="mb-0 px-2">Can't find your answers? Contact us at <span id="contactmail">contact@testandrank.com</span></p>
                    {/* <a href="#"> Contact us</a> */}
                </div>
</div>
</div>

<Footer />

      </>
  )
};

export default FaqsPage;
