import {React, useState, useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import Navbar from '../components/Navbar';
import axios from 'axios';
import SubCatCard from '../components/SubcatCard';
import Footer from '../components/Footer';
import '../styles/subcategorypage.css';

const SubCategory = (props) => {
    
    const id = props.match.params.catName.replaceAll("-"," ");
    const [allSubCategories, setAllSubCategories] = useState({});
    
    const getAllSubCategories = async () =>  {
         try {
            const json = JSON.stringify({ id: id });
            const headers = { 
                'Content-Type': 'application/json'
            };
           
            const res = await axios.post('https://compsciedu.com/services/service1.svc/Category', json, {headers}).then(response => {
                const subCatData = response.data.CategoryResult.SubCategoriesTypes;
                setAllSubCategories(subCatData);
            });
        } catch (error) {
            console.log(error);
        } 

    }
    
    useEffect(() => {
       getAllSubCategories();
    }, [id]);

    return (
        
        <>
        <MetaTags>
            <title>{id} Chapterwise MCQ online test - Test and Rank</title>
          </MetaTags>
        
        <Navbar />
        
        <div class="container subcat-desc">
        <div class="row">
    <div class="col">
    <div className="one">
    <h1 className='subcat-heading'>{id} Online Test</h1>
    </div>
    <div class="alert subcat-text">
  <p>Here you can attempt individual Chapterwise MCQ online tests under {id}. MCQ online test of individual topic will 
  cover questions from specific topic only. Check below the various tests avlaible under {id}. </p>
 </div>
    </div>
    </div>
</div>

        <div className="container">
       <SubCatCard allSubCategories = {allSubCategories} />
       
       </div>
       <Footer />
       </>
    )
}

export default SubCategory
