import {React, useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import '../styles/navbar.css';
import {Link} from 'react-router-dom';
import Logo from '../images/nav_icon.PNG'

const Navbar = () => {
  const [isLogin, setIsLogin] = useState(false);
  let history = useHistory();
  var item ="";
  const itemStr = localStorage.getItem('isLogin');
  item = JSON.parse(itemStr)
	const now = new Date()
  
  useEffect(() => {
  if (itemStr && item.expiry > now.getTime()) {
    setIsLogin(true);
  }
}, []);

  

  function Logout() {
    localStorage.removeItem('isLogin')
    history.push({
      pathname: '/',
  });
    window.location.reload(false)
  }

    return (
        <>         
<nav  className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <button
      className="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
    <img src="https://img.icons8.com/android/24/ffffff/align-justify.png"/>
    </button>

    <div className="collapse navbar-collapse col-lg-9" id="navbarSupportedContent">
      
   
      <Link to='/' className="navbar-brand mt-2 mt-lg-0 d-none d-sm-block">
      <img
              src={Logo}
              height='40px'
              width='120'
              alt=''
              loading='lazy'
            /></Link>
       


      <ul className="navbar-nav me-auto mb-2 mb-lg-0">

      <li className="nav-item d-block d-sm-none">
          <Link to="/" className="nav-link">Test and Rank</Link>
        </li>

        <li className="nav-item">
          <Link to="/online-test/Python" className="nav-link">Python Tests</Link>
        </li>

        <li className="nav-item">
          <Link to="/online-test/DBMS" className="nav-link">DBMS Tests</Link>
        </li>

        <li className="nav-item">
          <Link to="/online-test/C-Programming" className="nav-link">C Programming Tests</Link>
        </li>

        <li className="nav-item">
          <Link to="/online-test/Neural-Networks" className="nav-link">Neural Networks Tests</Link>
        </li>
        
      </ul>
    </div>
    
    <div className="col-lg-3 welcome-div">
    {(() => {

          if (isLogin) {
          return (
        <>  
       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
         <span className='wctext'>Welcome</span> <span className='username'>{(item.username.substring(0,14))}</span>
        </li></ul>

        
      <a
      className="dropdown-toggle align-items-center hidden-arrow"
      href="#"
      id="navbarDropdownMenuLink"
      role="button"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="numberCircle">{item.firstname.charAt(0).toUpperCase()}</div>
    </a>
   
    <ul
      className="dropdown-menu dropdown-menu-end"
      aria-labelledby="navbarDropdownMenuLink"
    >
      <li>
        <Link className="dropdown-item" to="/user/check-your-progress">My Progress</Link>
      </li>
      <li>
        <Link className="dropdown-item" to="/user/change-your-password">Change Password</Link>
      </li>
      <li>
        <button onClick={Logout} className="dropdown-item">Logout</button>
      </li>
      
    </ul>
    </> 
          )
        
        } else {
          return (
            <>
            <Link to='/login' type="button" class="login-btn">
          Login
        </Link>
        <Link to='/signup' type="button" class="signup-btn me-3">
          Sign up for free
        </Link>
        </>
          )
        }
      })()}
  
    </div>
  </div>
</nav>
        </>
    )
}

export default Navbar
