import React from 'react';
import { useState } from "react";
import axios from 'axios';
import { MetaTags } from 'react-meta-tags';
import { useHistory } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import '../styles/loginpage.css';
import Logo from '../images/logo.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Navbar from '../components/Navbar';
import {Link} from 'react-router-dom';

const ChangePasswordPage = () => {

    const [changepasserror, setChangePassError] = useState(false);
    const [changepassmessage, setChangePassMessage] = useState(false);
    const history = useHistory();
    var item ="";

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    if (!itemStr && item.expiry > now.getTime()) {
      history.push('/login');
    }

    function Logout() {
        localStorage.removeItem('isLogin')
      }

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .min(8, 'Password must be at least 8 character.')
            .required('Current Password is required.'),
        newPassword: Yup.string()
            .notOneOf([Yup.ref('currentPassword'), null],'New Password cannot be same as the current password.')
            .min(8, 'Password must be at least 8 character.')
            .required('New Password is required.'),    
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
            .required('Confirm New Password is required.'),    
        
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema)
  });

  function onSubmit(data) {
    setChangePassError(false);
    setChangePassMessage(false);
    try {
       const json = JSON.stringify({userid: item.userid, curpass:data.currentPassword, newpass:data.newPassword });

       const headers = { 
           'Content-Type': 'application/json'
       };
      
       const res = axios.post('https://compsciedu.com/services/service1.svc/ChangeUserPassword', json, {headers}).then(response => {
           const userData = response.data;
           console.log(response.data.ChangeUserPasswordResult);
            
             if(userData.ChangeUserPasswordResult == "Password changed successfully."){
                reset({ currentPassword: '', newPassword: '', confirmNewPassword:'' });
                setChangePassMessage(true);
                Logout();
             }
             else if(userData.ChangeUserPasswordResult == "Current password is Wrong." ){
                setChangePassError(true);
             }
           
       });
   } catch (error) {
       console.log(error);
   } 
  }

    return (
        <>
<MetaTags>
            <title>Change User Password - Test and Rank</title>
            <meta id="meta-description" name="description" />           
</MetaTags>

        <Navbar />
        <div className='BoxContainer'>
          <div className='FormContainer'>

          <div>
    <img width="120px" height="120px" src={Logo} alt="Test and Rank logo" />
    </div>

    <div className='login-heading'>
      <h3>CHANGE PASSWORD</h3>
    </div>

        <div className="Login">
           <Form id="changepass-form" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
<div class="form-group row login-form">
    <div class="col-sm-12 pass-wrapper">
    <label className='signup-label'>Current Password:</label>
      <input name="currentPassword" maxLength={25} type="password" {...register('currentPassword', { required: true })} className={`form-control ${errors.currentPassword ? 'is-invalid' : ''}`} placeholder="Current Password" />
        <div className="invalid-feedback">{errors.currentPassword?.message}</div>
    </div>

    <div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'> New Password:</label>
    <input name="newPassword" maxLength={25} type= "password" {...register('newPassword', { required: true })} className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`} placeholder="New Password" />
    
      <div className="invalid-feedback email-error">{errors.newPassword?.message}</div>
  </div>
</div>

<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Confirm New Password:</label>
    <input name="confirmNewPassword" maxLength={25} type= "password" {...register('confirmNewPassword', { required: true })} className={`form-control ${errors.confirmNewPassword ? 'is-invalid' : ''}`} placeholder="Confirm New Password" />
    
      <div className="invalid-feedback email-error">{errors.confirmNewPassword?.message}</div>
  </div>
  
</div>
  </div>
 
  <div class="form-group row login-form">
    <div class="col-sm-10 login-btn-div">
      <button  type="submit" className="btn btn-primary login-btn btn-lg btn-block">Change Password</button>
    </div>
  </div>

          </Form>  
          </div>

{changepasserror ?<div className="invalid-user">Current Password is wrong. Please try again.</div> : ''}

{changepassmessage ?<div className="invalid-user">Password changed successfully. <Link to="/login">Click here</Link> to Log in and continue.</div> : ''}
 
          </div>
        </div>
        </>
      );
}

export default ChangePasswordPage
