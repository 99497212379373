import {React, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import '../styles/lefttestconsole.css';


const LeftTestConsole = ({setQues, ansArray, catName, quesData, type, testId, curQues, visitedArr, MyTimer, time, timeExpired, rankArray}) => {

    let arr = Array.apply(null, {length: 30}).map(Number.call, Number);
    const history = useHistory();

    useEffect(() => {
        if(timeExpired !== 0){
         history.push({
                
                pathname:  `/online-test-result/${catName.replaceAll(" ","-")}`,
                state: {ansArray: ansArray, catName: catName, quesData: quesData, type, testId: testId, rankArray: rankArray},
                
            })          
        } 
      }, [timeExpired]);

    return (    
        <>

<div className='test-summary'>
            <ul className='test-summary-list'>
        <li><button className="nav-btn-checked indicator-btn">{ansArray.filter(ans => ans != undefined).length}</button><span class="indicator-text">Answered</span></li>
        {visitedArr.length != 30 ?  <li><button className="nav-btn-visited indicator-btn">{visitedArr.filter(ans => ans != undefined).length+1 - ansArray.filter(ans => ans != undefined).length }</button><span class="indicator-text">Not Answered</span></li>:
       <li><button className="nav-btn-visited indicator-btn">{visitedArr.filter(ans => ans != undefined).length - ansArray.filter(ans => ans != undefined).length}</button><span class="indicator-text">Not Answered</span></li>
       }
       {visitedArr.length != 30 ? <li><button className="nav-btn indicator-btn">{quesData.length - visitedArr.filter(ans => ans != undefined).length-1}</button><span class="indicator-text">Not Visited</span></li>:
       <li><button className="nav-btn indicator-btn">{quesData.length - visitedArr.filter(ans => ans != undefined).length}</button><span class="indicator-text">Not Visited</span></li>
       }       
            </ul>
</div>


      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
       <div class="btn-group mr-2" role="group" aria-label="First group">

               {arr.map(item => {
                   console.log(item);

                 if(curQues == item){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-current">{item+1}</button>
                   }
                 else if(ansArray[item]){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-checked">{item+1}</button>
                   }
                 else if(visitedArr[item] == item){
                    return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn-visited">{item+1}</button>
                   }
                  else
                {return <button onClick = {() => {setQues(item)}} type="button" className="nav-btn">{item+1}</button>}
            })}

        </div>
        </div>

<div id="submit-div">
        <button type="button" className="btn btn-danger sub-btn" data-mdb-toggle="modal" data-mdb-target="#staticBackdrop">
        Submit Test
</button>
</div>

<div
  class="modal fade"
  id="staticBackdrop"
  data-mdb-backdrop="static"
  data-mdb-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Test Summary</h4>
        <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
      <table class="table table-striped">
  
  <tbody>
    <tr>
      <th scope="row">Time Left</th>
      <td><MyTimer expiryTimestamp={time} /></td>
      
    </tr>
    <tr>
      <th scope="row">Attempted Questions</th>
      <td style={{textAlign: 'center', color: '#73a24e'}}>{ansArray.filter(ans => ans != undefined).length}</td>
      
    </tr>
    <tr>
      <th scope="row">Unattempted Questions</th>
      <td style={{textAlign: 'center', color: '#d03f2f'}}>{quesData.length - ansArray.filter(ans => ans != undefined).length}</td>
      
    </tr>
  </tbody>
</table>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success btn-sm" data-mdb-dismiss="modal">Resume Test</button>
        <Link to={{
            pathname: `/online-test-result/${catName.replaceAll(" ","-")}`,
            state: {ansArray: ansArray, catName: catName, quesData: quesData, type, testId: testId, rankArray: rankArray} 
        }}
        type="button">
        <button data-mdb-dismiss="modal" class="btn btn-danger btn-sm">Finish test</button>
        </Link>
      </div>
    </div>
  </div>
</div>
        </>
    )
}

export default LeftTestConsole
