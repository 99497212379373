import React from 'react';
import Logo from '../images/logo.png'
import '../styles/footer.css'
import { MDBIcon } from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';

const Footer = () => {

    return (
        <>
    <footer className="footer-59391">
      <div className="container">   
        <div className="row mb-5 flex-re ">

        <ul class="footer-list-top">
          <div className="col">
            <div className="site-logo">
              <Link to="/">Test and Rank</Link>
            </div>
          </div>
          </ul>

          <ul class="footer-list-top">
          <div id='logo-container' className="col">
    <img width="150px" height="150px" src={Logo} alt="Test and Rank logo" />
    <span id='footer-mail'>Mail : contact@testandrank.com</span>
    </div>
    </ul>
          
    <ul class="footer-list-top">
          <div className="col text-md-right">       
            <ul className="list-unstyled social-icons">
              <li><a href="#" className="fb footer-icons"><MDBIcon icon='facebook-f' /></a></li>
              <li><a href="#" className="in footer-icons"><MDBIcon icon="instagram" /></a></li>
              <li><a href="#" className="be footer-icons"><MDBIcon icon="behance" /></a></li>
              <li><a href="#" className="dr footer-icons"><MDBIcon icon="dribbble" /></a></li>
              <li><a href="#" className="yt footer-icons"><MDBIcon icon="youtube" /></a></li>
            </ul>
          </div>
          </ul>
        </div>
    <div className='container'>
        <div className="row mb-5 bottom-links">
          <div className="col">
            <ul className="nav-links list-unstyled nav-left">
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/frequently-asked-questions">FAQs</Link></li>
            </ul>
          </div>

  <div className="col text-md-right">
            <ul className="nav-links list-unstyled nav-right">
              <li><Link to="/">Home</Link></li>         
              <li><Link to="/about-us">About us</Link></li>
            </ul>
          </div>
        </div>
        </div>
        <div className="row">
          <div className="col ">
            <div className="copyright">
              <p><small>Copyright 2022. All Rights Reserved.</small></p>
            </div>
          </div>
        </div>
      
      </div>
      </footer>
  </>
    )
}

export default Footer


