import React from 'react'

const GeneralInstructions = ({quesLength}) => {
    return (
        <>

        <div className='BoxContainer'>
        <div id ="gen-instructions">
        
        <h4 class="gen-heading">General Instructions:</h4>

        <p>1. This test comprises multiple-choice questions (MCQs).Total number of multiple 
                choice questions in this online test is {quesLength}. 
                Each question will have only 1 of the available options as the correct answer.</p>

            <p>2. The clock will be set at the server. The countdown timer at the top right 
            corner of screen will display the remaining time available for you to complete the examination. When the timer reaches zero, the examination will end by itself. You can end your exam before the time by clicking the Submit Button.
        </p>

        <p>3. The Question Palette displayed on the left side of screen will show the 
        status of each question using one of the following symbols:
        <div id="symbol-status">
            <ul class="symbols">
        <li><button  type="button" className="nav-btn-checked ins-btn"></button><span class="symbol-text">Answered</span></li>
        <li><button type="button" className="nav-btn ins-btn"></button><span class="symbol-text">Not Visited</span></li>
        <li><button className="nav-btn-visited ins-btn"></button><span class="symbol-text">Visited but Not Answered</span></li>
        <li><button type="button" className="nav-btn-current ins-btn"></button><span class="symbol-text">Currently Selected Question</span></li>
        
        </ul>
        </div>
        </p>

        <p>4. Click on the question number in the Question Palette at the left of your 
            screen to go to that numbered question directly. Alternatively, you can use the Next and Prev
            button to move to Next and Previous question respectively.</p>

            <p>5. To choose one answer from the 4 options (A,B,C,D) given below the question, click on the bubble placed before the option.</p>

        <p>6. Your response to a question is automatically saved when you navigate from the existing question and the 
            the button corresponding to that question turns green.
        </p>

        <p>7. To deselect your chosen answer, click on the Clear Response button.
To change your chosen answer, click on the bubble of another option.</p>

       

        </div>
        </div>

        </>
    )
}

export default GeneralInstructions
