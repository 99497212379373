import React from 'react';
import { MetaTags } from 'react-meta-tags';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const AboutUsPage = () => {
  return (
  <>
<MetaTags>
            <title>About Us - Test and Rank</title>
            <meta id="meta-description" name="description" />     
</MetaTags>

<Navbar />

<div class="faq_area" id="faq">
    <div class="container">

    <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-lg-6">

            <div class="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp'}}>
            <h3>A breif Introduction to Test and Rank</h3>
                    <div class="line"></div>
                </div>

<p>The fundamental aim of Test and Rank is to provide an online platform for computer science
students to attempt tests and track their progress. </p>

<p>We started this site with only few type of online tests questions but the goal is to cover 
all topics related to computer science and engineering. The ultimate goal is to create a 
community online learners for sharing knowledge and developing skills.</p>

<p>We started with few thousands questions in database but we are continuously updating it.</p>

<p>As a small team is maintaining this site, it may have some issues/bugs. Please feel free to 
point out them and let us know. Any feedback regarding content, design and user Experience will 
be greatly appreciated. You can contact us through E-mail or Facebook.</p>

<p>Let’s together make web a learning place.</p>
<h4>Technologies powering this site</h4>

<p>This site is built using React Web Development framework.</p>

</div>
</div>
</div>
</div>

<Footer />
  </>
  )
};

export default AboutUsPage;
