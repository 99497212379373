import React from "react";
import Home from './pages/HomePage';
import SubCategory from "./pages/SubCategoryPage";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import OnlineTest from "./pages/OnlineTestPage";
import TestResult from "./pages/TestResultPage";
import TestSolution from "./pages/TestSolutionPage";
import Login from "./pages/LoginPage";
import Signup from "./pages/SignupPage";
import UserProgress from "./pages/UserProgressPage";
import ChangePassword from "./pages/ChangePasswordPage";
import UserActivation from "./pages/UserActivationPage";
import ActivationMail from "./pages/ActivationMailPage";
import ForgetPassword from "./pages/ForgetPasswordPage";
import ResetPassword from "./pages/ResetPasswordPage";
import FAQs from "./pages/FaqsPage";
import GoToTop from "./components/GoToTop";
import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import AboutUs from "./pages/AboutUsPage";

function App() {
  return (
    <>
    <Router>
       <GoToTop />

      <Switch>
        <Route path = "/" exact component={ Home } />
        <Route path = "/online-test/:catName" exact component={SubCategory} />
        <Route path = "/online-mcq-test/:id" exact component={OnlineTest} />
        <Route path = "/online-test-result/:id" exact component={TestResult} />
        <Route path = "/online-test-solution/:id" exact component={TestSolution} />
        <Route path = "/login" exact component ={Login} />
        <Route path = "/signup" exact component ={Signup} />
        <Route path = "/online-mcq-test/:id" exact component={OnlineTest} />
        <Route path = "/user/check-your-progress/" exact component={UserProgress} />
        <Route path = "/user/change-your-password" exact component={ChangePassword} />
        <Route path = "/auth/user-activation/:code" exact component={UserActivation} />
        <Route path = "/auth/resend-activation-mail" exact component={ActivationMail} />
        <Route path = "/user/reset-your-password" exact component={ForgetPassword} />
        <Route path = "/auth/reset-password/:code" exact component={ResetPassword} />
        <Route path = "/frequently-asked-questions" exact component={FAQs} />
        <Route path = "/privacy-policy" exact component={PrivacyPolicy} />
        <Route path = "/about-us" exact component={AboutUs} />
      </Switch>
    
    </Router>
    </>
  );
}

export default App;
