import React from 'react'

const TestInstructions = ({catName, quesLength}) => {
    return (
        <>
        <div id="cat-heading">
        <h2>Online Test: {catName}</h2>
        </div>

        <div id='test-ins-div' class="container">
        <div class="row">
            <div class="col-6" id="marks-div">
            <span class="marks-time">Maximum Marks: {quesLength}</span>
            </div>
            <div class="col-6" id="time-div">
            <span class="marks-time">Duration: {quesLength} Mins</span>
            </div>    
        </div>
        
        <div class="row">
            <div class="col-12">
        <div className="instructions">
            <h7 id ="inst-heading">Read the following instructions carefully before begining the test.</h7>

            <p>The Test contain total of {quesLength} questions.</p>
            <p>Each question has 4 options out of which only one is correct.</p>
            <p>You have to finish the test in {quesLength} minutes.</p>   
            <p>There is no negative marking in this test.</p> 
            <p>You will be awarded 1 mark for each correct answer.</p>
            <p>At the end of test, your marks will be displayed along with other performance
                indicators like rank, accuracy,etc.
            </p>
        
            <p className='warning-instruction'>Once you start the test, you will not be allowed to pause it and continue later. Make sure that you complete the
             test before you submit the test and/or close the browser.
             Also, do not refresh the page once the test started.
             </p>

            </div>
        </div>
        </div>

       </div>
       
        </>
    )
}

export default TestInstructions
