import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react';
import {React} from 'react';
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco} from "react-syntax-highlighter/src/styles/hljs";

const MiddleTestConsole = ({questions, curQues, nextQues, preQues, clearResponse, setAnswer, ansArray}) => {

    return (
      <>
      <div id="console-div">
        <div id="ques-div">
            <table className="table">
  <thead>
    <tr>
      <th id ="option-column" scope="col">Que. {curQues+1}</th>
      <th scope="col"><span class="questionpre">{questions[curQues].Question}</span></th>
    </tr>

    {questions[curQues].CodeSnippet &&
    <tr>
    <td></td>
      <td scope="col"><SyntaxHighlighter language="C" style={docco}>{questions[curQues].CodeSnippet}</SyntaxHighlighter></td> 
    </tr>
}

{questions[curQues].Image1 &&
    <tr>
    <td></td>
      <td scope="col" class="img-column">
      <img 
      src={`https://compsciedu.com/QuestionImages/${questions[curQues].QuestionId}-1.png`}
      alt="new"
      />
        </td> 
    </tr>
}

{questions[curQues].Image2 &&
    <tr>
    <td></td>
      <td scope="col">
      <img 
      src={`https://compsciedu.com/QuestionImages/${questions[curQues].QuestionId}-2.png`}
      alt="new"
      />
        </td>  
    </tr>
}


  </thead>
  <tbody>
    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optiona" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option1} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option1}</td>
    </tr>
    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optionb" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option2} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option2}</td>
    </tr>
    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optionc" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option3} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option3}</td>
    </tr>
    <tr>
      <th scope="row"><input type="radio" className="ans-radio" id = "optiond" onClick={(e) => {setAnswer(e.target.value)}} value = {questions[curQues].Option4} name="selected_ans" /></th>
      <td className='option-row'>{questions[curQues].Option4}</td>
    </tr>
  </tbody>
</table>
        </div>

<div id="next-prev">
<div className="container">
  <div className="row">
  <div id ="prev" className="col"><button onClick = {preQues} type="button" className="btn btn-info ques-nav-btn">Prev</button></div>
  <div id="next" className="col"><button onClick = {nextQues} type="button" className="btn btn-info ques-nav-btn">Next</button></div>
  <div id="clear-res" className="col"><button onClick = {clearResponse} type="button" className="btn btn-dark">Clear Response</button></div>
</div>
</div>
        </div>
        </div>
        </>
    )
}

export default MiddleTestConsole
