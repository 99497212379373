import {React, useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import Topcontainer from '../components/Topcontainer';
import CatCardContainer from '../components/CatCardContainer';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';


const Home = () => {

    useEffect(() => {
        document.title = "";  
      }, []);
  
    return (
        <>
<MetaTags>
            <title>Test and Rank - Computer Science Online MCQ Test</title>
            <meta id="meta-description" name="description" 
            content="Attempt hundreds of MCQ based Computer Science Online Tests and quizzes on
            multiple topics like HTML online test, SQL online test, Javascript online test
            Python online test, DBMS test, Cloud Computing online test and many more." />
            {/* <meta id="og-title" property="og:title" content="MyApp" />
            <meta id="og-image" property="og:image" content="path/to/image.jpg" /> */}
          </MetaTags>

            <Navbar />
            <Topcontainer />
            <CatCardContainer />
            <Footer />
        </>
    )
}

export default Home
