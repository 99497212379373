const categoryData = [
    {
        
        id: 15,
        desc: "Attempt an instant Networking Test covering all the Networking topics like Network Models, Physical Layer, Ethernet, Routing, Wireless LANs, Network Layer Protocols, Application Layer Protocols, LAN Topologies and many more.",
    },
    {
        id: 16,
        desc: "This Operating System MCQ based online Test include topics like CPU Scheduling, Deadlocks, Page Replacement Algorithms, Virtual Memory and so on.",
    },
    {
        
        id: 17,
        desc: "Appear in Software Engineering Online Mock Test to test your knowledge in topics like Requirements Modeling, Software Components, Software Metrics, etc.",
    },
    {
        id: 18,
        desc: "Systems Programming quiz contains Multiple Choice Questions (MCQs) which will test your skills in Systems Programming Concepts such as Machine Structure, Assemblers, etc. in a detailed and comprehensive manner.",
    },
    {
        
        id: 19,
        desc: "Test your knowledge in advanced topics of Data Structures and Algorithms such as Arrays and Pointers, Linked Lists, Stacks and Queues, Recursion and more.",
    },
    {
        id: 20,
        desc: "Computer Architecture online Test attempt to check your knowledge in all important Computer Architecture subjects like Digital Logic Circuits, Digital Data Representation, 8085 Microprocessor,etc.",
    },
    {
        
        id: 21,
        desc: "This Database Management System quiz include MCQ questions from all the DBMS topics like Data Models, Data Storage and Querying, Normalization, Indexing and Hashing,Transactions, Concurrency Control, Data Warehousing and Data Mining and many more.",
    },
    {
        id: 22,
        desc: "Check your Preparation by attempting this online Computer Graphics Test. The test include questions from chapters like Two Dimensional Viewing, Three Dimensional Viewing, Computer Animation, etc.",
    },
    {
        
        id: 23,
        desc: "Artificial Intelligence online quiz test your knowledge and skills in topics like Intelligent Agents, Problem Solving, Knowledge and Reasoning, Natural Language Processing and many more.",
    },
    {
        id: 24,
        desc: "This Web Technologies Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like HTML and CSS, AJAX, Internet and Protocols and HTTP.",
    },
    {
        
        id: 25,
        desc: "Cloud Computing quiz contains MCQs which will test your skills in Cloud Computing Concepts such as Proposition of Cloud, Cloud Architecture,etc. in a comprehensive manner.",
    },
    {
        id: 26,
        desc: "Attempt an instant Microprocessor online Test covering all the Microprocessor topics like Microprocessor Components, 8086 Instruction Set, Microprocessor Architecture, RISC Architecture and many more.",
    },
    {
        
        id: 27,
        desc: "This IT Fundamentals quiz include MCQ questions from all the IT Fundamentals topics like Basic Organization of Computer, Input Output Devices, Classification of Computers, Computer Languages, Computer Security and many more.",
    },
    {
        id: 28,
        desc: "Appear in Computer Hardware Online Mock Test to test your knowledge in topics like Hardware Basics, MotherBoard, BIOS, Memory, System Bus, Microprocessor, Ports, Peripheral Devices etc.",
    },
    {
        
        id: 33,
        desc: "This Compiler Design MCQ based online Test include topics like Finite Automata and Regular Expression, Context Free Grammar and Syntax Analysis, Topdown Parsing, Bottom Up Parsing, Symbol Table and so on.",
    },
    {
        id: 42,
        desc: "Neural Networks quiz contains Multiple Choice Questions (MCQs) which will test your skills in Neural Networks Concepts such as Learning Concepts of Neural Networks, Activation and Synaptic Dynamics, etc. in a detailed and comprehensive manner.",
    },
    {
        id: 44,
        desc: "This Formal Languages and Automata Theory Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like Finite Automata, DFA Minimization, Context Free Grammars, etc.",
    },
    {
        
        id: 45,
        desc: "Test your knowledge in advanced topics of C Programming such as Functions, Control Flow Statements in C, Pointers and Arrays in C, Input and Output in C, Library Functions, Structures and Unions, File Handling in C, Macro and Preprocessor and more.",
    },
    {
        id: 46,
        desc: "Check your Preparation by attempting this online Object Oriented Programming Using C++ Test. The test include questions from chapters like Data Types in C++, Functions in C++, Classes and Operator Overloading in C++, etc.",
    },
    {
        
        id: 47,
        desc: "Management Information Systems online Test attempt to check your knowledge in all important Management Information Systems subjects like Organisational Systems, Computers and Information Systems, Database Technology, Expert Systems, etc.",
    },
    {
        id: 1048,
        desc: "Appear in MS Office Online Mock Test to test your knowledge in topics like MS Word, MS Powerpoint, MS Excel, MS Access, Shortcut Keys, etc.",
    },
    {
        
        id: 1049,
        desc: "Systems Analysis and Design Methods quiz contains Multiple Choice Questions (MCQs) which will test your skills in Systems Analysis and Design Methods Concepts such as Modeling System Requirements, Feasibility Analysis in a detailed and comprehensive manner.",
    },
    {
        id: 1050,
        desc: "This C# programming Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like Data Types,Variables and Operators , Control Instructions, LINQ, Functions and Subroutines, etc.",
    },
    {
        
        id: 1051,
        desc: "JavaScript quiz contains Multiple Choice Questions (MCQs) which will test your skills in JavaScript Concepts such as Classes and Modules, Graphics and Rendering, Performance Measures in JavaScript, Socket Programming with JavaScript, etc. in a detailed and comprehensive manner.",
    },
    {
        id: 1052,
        desc: "Test your knowledge in advanced topics of Cryptography and Network Security such as Encryption Techniques, Data Encryption Standard, Advanced Encryption Algorithm, Cryptographic Hash Functions, Public Key Cryptography and RSA and more.",
    },
    {
        
        id: 1053,
        desc: "Appear in Digital Image Processing (DIP) Online Mock Test to test your knowledge in topics like Digital Image Fundamentals, Image Compression, Filtering in Frequency Domain , Color Image Processing, Image Segmentation, Image Enhancement etc.",
    },
    {
        id: 2053,
        desc: "Check your Preparation by attempting this online UNIX Test. The test include questions from chapters like Unix File System, Vi Editor, Unix Shell, Filters and Regular Expressions, Shell Programming, Basic System Administration, etc.",
    },
    {
        
        id: 3053,
        desc: "Digital Logic Design online Test attempt to check your knowledge in all important Digital Logic Design subjects like Algorithmic State Machine, Synchronous Sequential Logic, Binary Systems, Digital Integrated Circuits, etc.",
    },
    {
        id: 3055,
        desc: "Appear in HTML Online Mock Test to test your knowledge in topics like HTML Fundamentals, HTML and XHTML, HTML Images and Web Page Files, HTML5, etc.",
    },
    {
        
        id: 3056,
        desc: "Object Oriented Programming quiz contains Multiple Choice Questions (MCQs) which will test your skills in Object Oriented Programming Concepts such as Objects and Classes in OOPs, Constructors and Destructors, Access Specifiers, Member Functions, etc. in a detailed and comprehensive manner.",
    },
    {
        id: 4056,
        desc: "Appear in PHP Online Mock Test to test your knowledge in topics like Arrays and Functions in PHP, Object Oriented PHP, Error and Exception Handling in PHP, Session Handling in PHP, PHP Controls Structures, Constants and Operators in PHP, etc.",
    },
    {
        
        id: 4057,
        desc: "Software Architecture and Design Methods quiz contains Multiple Choice Questions (MCQs) which will test your skills in Software Architecture and Design Concepts such as Software Design Patterns, Requirement Specification and Planning, UML Diagrams in a detailed and comprehensive manner.",
    },
    {
        id: 4058,
        desc: "Appear in Embedded Systems Online Mock Test to test your knowledge in topics like Embedded Processors, Memory Techonology of Embedded Systems, Interrupts and Exceptions, Embedded Operating System, Implementing Embedded Systems, Specification and Validation  etc.",
    },
    {
        
        id: 4059,
        desc: "Java Programming online Test attempt to check your knowledge in all important Java Programming subjects like Operators and Control Statements, String Handling, java lang and java io, Multithreading, etc.",
    },
    {
        id: 4060,
        desc: "This Python MCQ based online Test include topics like Formatting and Decorators, Strings in Python, Loops in Python, Regular Expressions and Files and so on.",
    },
    {
        
        id: 4061,
        desc: "Test your knowledge in advanced topics of Cryptography and Network Security such as Encryption Techniques, Data Encryption Standard, Advanced Encryption Algorithm, Cryptographic Hash Functions, Public Key Cryptography and RSA and more.",
    },
    {
        id: 4062,
        desc: "This Java Spring Framework Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like AspectJ and Scripting in Spring, Spring Security, Spring MVC and Spring REST, Grails and Spring Testing, etc.",
    },
    {
        
        id: 4063,
        desc: "Appear in CSS Online Mock Test to test your knowledge in topics like CSS Fundamentals and Styling, CSS Selectors and Files, CSS Properties and Elements , Tables and Forms, etc.",
    },
    {
        id: 4074,
        desc: "Check your Preparation by attempting this online Discrete Mathematics Test. The test include questions from chapters like Logics and Proofs, Sets and Functions, Number Theory and Cryptography, Counting Theory, Discrete Probability, Group Theory, etc.",
    },
    {
        
        id: 4075,
        desc: "Attempt an instant Cyber Security Test covering all the Cyber Security topics like Ethical Hacking and Security Ethics, Cyber Laws and Security Tools, Cyber Security Types, Understanding Attack Vectors and many more.",
    },
    {
        id: 4076,
        desc: "Appear in Hadoop Online Mock Test to test your knowledge in topics like MapReduce Basics, Hadoop Distributed File System, Hadoop IO, Introduction to Pig, Hive and HBase, Hadoop Frameworks, etc.",
    },
    {
        
        id: 4077,
        desc: "This Internet of Things (IoT) MCQ based online Test include topics like IoT Ecosystem and Architecture, IoT Hardware, Software Programming and IoT, IoT Networking Protocols and so on.",
    },
    {
        id: 4078,
        desc: "This MongoDB Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like Data Modeling in MongoDB, MongoDb Datatypes, Indexes In MongoDB, Optimization Strategies for MongoDB, etc.",
    },
    {
        
        id: 4079,
        desc: "Check your Preparation by attempting this online MySQL Database. The test include questions from chapters like MySQL Basics, MySQL Data Types and Operators, Stored Programs, Query Optimization, MySQL Programming, etc.",
    },
    {
        id: 4080,
        desc: "SQL Server quiz contains Multiple Choice Questions (MCQs) which will test your skills in SQL Server Concepts such as Data Modification and Constraints, Data Definition Language, Data Connectivity in a detailed and comprehensive manner.",
    },
    {
        id: 4081,
        desc: "Attempt an instant Wireless and Mobile Communications Test covering all the topics like Modern Wireless Communication Systems, Cellular Communication Concepts, Mobile Radio Propagation, Modulation Techniques for Mobile Radio and many more.",
    },
    {
        
        id: 4082,
        desc: "Check your Preparation by attempting this online Data Science Test. The test include questions from chapters like Data Analysis with Python, Data Manipulation, Data Analysis and Research, Machine Learning, Statistical Inference and Regression Models, etc.",
    },
    {
        id: 4083,
        desc: "This MATLAB Online Mock Test is for those aspirants who are preparing for interviews and exams for topics like MATLAB Graphics, MATLAB Programming, Simulink and GUIs, Control System Applications, etc.",
    },
    {
        id: 4084,
        desc: "R Programming quiz contains Multiple Choice Questions (MCQs) which will test your skills in R Programming Concepts such as Data Types and Dataset Reading, Control Structures and Functions, Scoping Rules and Debugging, Commands and Packages, etc. in a detailed and comprehensive manner.",
    },
    {
        id: 4085,
        desc: "This Visual Basic MCQ based online Test include topics like User Interface, Selection Structures, Repetition Structure, Arrays and String Manipulation and so on.",
    },
 
    
];

export default categoryData;