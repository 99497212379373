import React from 'react';
import MetaTags from 'react-meta-tags';
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco} from "react-syntax-highlighter/src/styles/hljs";
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import '../styles/testsolutionpage.css';

const TestSolutionPage = (props) => {

  var item ="";
  const history = useHistory();

  let catName = "";
  let quesData = "";
  let ansArray = "";

  const itemStr = localStorage.getItem('isLogin');
     item = JSON.parse(itemStr)
     const now = new Date()

     if (!itemStr || item.expiry < now.getTime()) {
      history.push('/login');
      window.location.reload(false);
    }

    if(props.location.state){
       catName = props.location.state.catName;
       quesData = props.location.state.quesData;
       ansArray = props.location.state.ansArray;
    }
    
    return (
        <>

<MetaTags>
            <title>{catName} Online MCQ Test Answers  - Test and Rank</title>
            <meta id="meta-description" name="description" 
             />     
</MetaTags>

        <Navbar /> 

<div className="container-fluid head-div">
<div className="row">

        <div className="col">
          <h5 className = "test-heading">
                {catName} Online Test
        </h5>
        </div>
        </div>
        </div>

<div className="container">
  <div className="row result-block sol-container">
    <div className="col-sm seven">
      <h1>Solutions</h1>
    </div>
  </div>

          {quesData.map((ques,key) => {  
              return(
        <div id="console-div">
          <div id="ques-div-sol">
          <div className="performance-card">
              <table className="table">
    <thead>
      <tr>
        <th id ="option-column" scope="col">Que. {key+1}</th>
        <th scope="col"><span class="questionpre">{ques.Question}</span></th>
      </tr>
  
      {ques.CodeSnippet &&
      <tr>
      <td></td>
        <td scope="col"><SyntaxHighlighter language="C" style={docco}>{ques.CodeSnippet}</SyntaxHighlighter></td> 
      </tr>
  }
  
  {ques.Image1 &&
      <tr>
      <td></td>
        <td scope="col" class="img-column">
        <img 
        src={`https://compsciedu.com/QuestionImages/${ques.QuestionId}-1.png`}
        alt="new"
        />
          </td>    
      </tr>
  }
  
  {ques.Image2 &&
      <tr>
      <td></td>
        <td scope="col">
        <img 
        src={`https://compsciedu.com/QuestionImages/${ques.QuestionId}-2.png`}
        alt="new"
        />
          </td>    
      </tr>
  }
  
    </thead>
    <tbody>
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option1} className="ans-radio" id = "optiona" value = {ques.Option1} disabled="disabled"  /></th>
         {ques.Option1 == ques.Answer ? <td>{ques.Option1}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option1}</td>} 
  
      </tr>
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option2} className="ans-radio" id = "optionb" value = {ques.Option2} disabled="disabled" /></th>
        {ques.Option2 == ques.Answer ? <td>{ques.Option2}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option2}</td>} 
        
      </tr>
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option3} className="ans-radio" id = "optionc" value = {ques.Option3}  disabled="disabled" /></th>
        {ques.Option3 == ques.Answer ? <td>{ques.Option3}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option3}</td>} 
        
      </tr>
  
      <tr>
        <th scope="row"><input type="radio" checked={ansArray[key] == ques.Option4} className="ans-radio" id = "optiond" value = {ques.Option4}  disabled="disabled" /></th>
        {ques.Option4 == ques.Answer ? <td>{ques.Option4}<span class="checkmark">
    <div class="checkmark_circle"></div>
    <div class="checkmark_stem"></div>
    <div class="checkmark_kick"></div>
</span></td> : <td>{ques.Option4}</td>}  
      </tr>

    </tbody>
  </table>
          </div>
  
          </div>
          </div>
              )
})    
}

<div className='row result-block btn-container'>
        <div className="performance-card">
        <button onClick={history.goBack} className="btn btn-dark">Go back to Analysis</button>

<Link to={{
    pathname: '/', 
}}
        className="btn btn-dark">Go to Tests</Link>
        </div>
        </div>

  </div>
  
          </>
      )
    }

export default TestSolutionPage
