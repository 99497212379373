import React from 'react'
import '../styles/homepagecard.css'
import images from './ImagesImport';
import { Link } from "react-router-dom";
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from 'mdb-react-ui-kit';


const HomePageCard = ({ allCategories, catData}) => {    
    
    const catArray = Array.from(allCategories);
    const catDataArray = Array.from(catData);
    const itemStr = localStorage.getItem('isLogin');
    const item = JSON.parse(itemStr)
    const now = new Date()

    if(!itemStr || item.expiry < now.getTime()){
        localStorage.clear();
        
    }

    return (
        <>
           <div className ="d-flex .container"> 
          {catArray.map((curCat, key) => {
             
              return(
                  <>            
                  <div className="col col-xs-2 main-card-holder">
                    <div className="card" style={{width: "19rem"}}>
 <div className="img-container">                       
 <img className="card-img-top" src={images[key]} alt="Card image cap" />
 </div>
<div className="card-body" key = {curCat.CategoryId}>
<h5 className="card-title">{curCat.CategoryName}</h5>
<p className="card-text">{catDataArray.find(x => x.id === curCat.CategoryId).desc}</p>
</div>

     {
         (itemStr) ?
<ul className="list-group list-group-flush">
 <li className="list-group-item">
 <Link to={{
    pathname: `/online-mcq-test/${curCat.CategoryName.replaceAll(" ","-")}`,
    state: 'category-test'
    
}} type="button" className="btn btn-success btn-lg">Attempt Now</Link> </li></ul>: 

<ul className="list-group list-group-flush">
 <li className="list-group-item">
     
 <MDBPopover tag="span" container= 'body' size='lg' color='danger' placement='top'
 btnChildren={<a className="btn btn-success btn-lg">Attempt Now</a>} dismiss>
      <MDBPopoverHeader>Login or Sign up First- It's free !</MDBPopoverHeader>
      <MDBPopoverBody>You need to <Link to='/login' type="button" class="card-login-btn">
          Login
        </Link> <span>or </span>
        <Link to='/signup' type="button" class="card-login-btn">
           Sign Up
        </Link> to Get Started.</MDBPopoverBody>
    </MDBPopover>
     </li></ul>
          }

<div className="card-body">
<p className="card-text" style={{"marginTop":"0"}}>You can also see all the online tests avaliable under individual topics of {curCat.CategoryName} subject by clicking the link below.</p>
<Link to={{
    pathname: `/online-test/${curCat.CategoryName.replaceAll(" ","-")}`,
    
}} className="card-link link-danger">View All Quizzes under {curCat.CategoryName}</Link> 
</div>
</div>
</div> 
                  </>
              )

          })}

      </div>         
        </>    
    )
}

export default HomePageCard