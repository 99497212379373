import React from 'react';
import { useState } from "react";
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import  { Redirect } from 'react-router-dom';
import '../styles/loginpage.css';
import Logo from '../images/logo.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';

const SignupPage = () => {

    const [passwordShown, setPasswordShown] = useState(false);
    const [signuperror, setSignUpError] = useState(false);
    const [signupmessage, setSignUpMessage] = useState(false);

    var item ="";
    const history = useHistory();

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

     if (itemStr && item.expiry > now.getTime()) {
      history.push('/');
    }

      const validationSchema = Yup.object().shape({
        firstName: Yup.string()
        .required('First name is required.')
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field."),
        lastName: Yup
        .string()
        .nullable()
        .notRequired()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field."),
        userName: Yup.string()
        .required('Username is required.'),
        email: Yup.string()
            .required('Email is required.')
            .email('Email is invalid.'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 character.')
            .required('Password is required.'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match.')
            .required('Confirm Password is required.'),    
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema)
  });

      function onSubmit(data) {
          setSignUpError(false);
          setSignUpMessage(false);
          try {
             const json = JSON.stringify({fname:data.firstName, lname:data.lastName, uname:data.userName, email: data.email, pass: data.password });
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://compsciedu.com/services/service1.svc/SubmitUserData', json, {headers}).then(response => {
                 const userData = response.data;
                if(userData.SubmitUserDataResult.Firstname == "Email id already exist" && userData.SubmitUserDataResult.Lastname == "Signup Failed" ){
                     setSignUpError(true);
                   }
                   else if(userData.SubmitUserDataResult.Firstname == "User Registered Successfully" && userData.SubmitUserDataResult.Lastname == "Signup Successful" ){
                     setSignUpMessage(true);
                     document.getElementById("signup-form").reset();
                   }  
             });
         } catch (error) {
             console.log(error);
         } 
        }

    return ( 
        <>
<MetaTags>
            <title>Sign up for a Account - Test and Rank</title>
            <meta id="meta-description" name="description" 
             />      
</MetaTags>

    <Navbar />
        <div className='BoxContainer'>
        <div className='FormContainer'>
        <div>
  <img width="120px" height="120px" src={Logo} alt="Test and Rank logo" />
  </div>

  <div className='login-heading'>
    <h3>SIGN UP</h3>
  </div>

      <div className="Login">
         <Form id="signup-form" onSubmit={handleSubmit(onSubmit)}>

<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>First Name:</label>
    <input name="firstName" maxLength={40} {...register('firstName', { required: true })} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="First Name"/>
    <div className="invalid-feedback email-error">{errors.firstName?.message}</div>
  </div>
</div>


<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Last Name:</label>
    <input name="lastName" maxLength={40} {...register('lastName', { required: false})} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="Last Name"/>
    <div className="invalid-feedback email-error">{errors.lastName?.message}</div>
  </div>
</div>

<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Username:</label>
    <input name="userName" maxLength={15} {...register('userName', { required: true})} className={`form-control ${errors.userName ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="Choose a Username"/>
    <div className="invalid-feedback email-error">{errors.userName?.message}</div>
  </div>
</div>

<div class="form-group row signup-form">
    <div class="col-sm-12">
    <label className='signup-label'>Email:(Verification Required)</label>
      <input name="email" maxLength={60} {...register('email', { required: true })} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="Email"/>
      <div className="invalid-feedback email-error">{errors.email?.message}</div>
    </div>
  </div>


<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Password:</label>
    <input name="password" maxLength={25} type= "password" {...register('password', { required: true })} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" />
      <div className="invalid-feedback email-error">{errors.password?.message}</div>
  </div>
</div>

<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Confirm Password:</label>
    <input name="confirmpassword" maxLength={25} type= "password" {...register('confirmPassword', { required: true })} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} placeholder="Confirm Password" />
      <div className="invalid-feedback email-error">{errors.confirmPassword?.message}</div>
  </div>
</div>

<div class="form-group row login-form">
  <div class="col-sm-10 login-btn-div">
    <button  type="submit" className="btn btn-primary login-btn btn-lg btn-block">Sign Up</button>
  </div>
</div>

        </Form> 
        </div>

        {signuperror ?<div className="invalid-user">This Email Id is already registered.</div> : ''}

        {signupmessage ?<div className="invalid-user">Registration Successful. Please check your Email for the Activation Link to activate your account.</div> : ''}
        
        <div>
        <span className='login-text'>Already have an account? <Link to="/login">Log In</Link></span>
        </div>

        </div>
      </div>
      </>
    )
}

export default SignupPage
