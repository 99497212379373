import Networking from "../images/Networking.png";
import OperatingSystem from "../images/Operating System.png";
import SoftwareEngineering from "../images/Software Engineering.png";
import SystemsProgramming from "../images/Systems Programming.png";
import DataStructuresandAlgorithms from "../images/Data Structures and Algorithms.png";
import ComputerArchitecture from "../images/Computer Architecture.png";
import DBMS from "../images/DBMS.png"
import ComputerGraphics from "../images/Computer Graphics.png"
import ArtificialIntelligence from "../images/Artificial Intelligence.png"
import WebTechnologies from "../images/Web Technologies.png"
import CloudComputing from "../images/Cloud Computing.png"
import Microprocessor from "../images/Microprocessor.png"
import ITFundamentals from "../images/IT Fundamentals.png"
import ComputerHardware from "../images/Computer Hardware.png"
import CompilerDesign from "../images/Compiler Design.png"
import NeuralNetworks from "../images/Neural Networks.png"
import SFormalLanguagesandAutomataTheory from "../images/Formal Languages and Automata Theory.png"
import CProgramming from "../images/C Programming.png"
import ObjectOrientedProgrammingUsingC from "../images/Object Oriented Programming Using C++.png"
import ManagementInformationSystems from "../images/Management Information Systems.png"
import MSOffice from "../images/MS Office.png"
import SystemsAnalysisandDesignMethods from "../images/Systems Analysis and Design Methods.png"
import Csharpprogramming from "../images/C# programming.png"
import JavaScript from "../images/JavaScript.png"
import CryptographyandNetworkSecurity from "../images/Cryptography and Network Security.png"
import DigitalImageProcessing from "../images/Digital Image Processing (DIP).png"
import UNIX from "../images/UNIX.png"
import DigitalLogicDesign from "../images/Digital Logic Design.png"
import HTML from "../images/HTML.png"
import ObjectOrientedProgramming from "../images/Object Oriented Programming.png"
import PHP from "../images/PHP.png"
import SoftwareArchitectureandDesign from "../images/Software Architecture and Design.png"
import EmbeddedSystems from "../images/Embedded Systems.png"
import JavaProgramming from "../images/Java Programming.png"
import Python from "../images/Python.png"
import DigitalCommunication from "../images/Digital Communication.png"
import JavaSpringFramework from "../images/Java Spring Framework.png"
import CSS from "../images/CSS.png"
import DiscreteMathematics from "../images/Discrete Mathematics.png"
import CyberSecurity from "../images/Cyber Security.png"
import Hadoop from "../images/Hadoop.png"
import InternetofThings from "../images/Internet of Things (IoT).png"
import MongoDB from "../images/MongoDB.png"
import MySQLDatabase from "../images/MySQL Database.png"
import SQLServer from "../images/SQL Server.png"
import WirelessandMobileCommunications from "../images/Wireless and Mobile Communications.png"
import DataScience from "../images/Data Science.png"
import MATLAB from "../images/MATLAB.png"
import RProgramming from "../images/R Programming.png"
import VisualBasic from "../images/Visual Basic.png"


export default [
    Networking,
    OperatingSystem,
    SoftwareEngineering,
    SystemsProgramming,
    DataStructuresandAlgorithms,
    ComputerArchitecture,
    DBMS,
    ComputerGraphics,
    ArtificialIntelligence,
    WebTechnologies,
    CloudComputing,
    Microprocessor,
    ITFundamentals,
    ComputerHardware,
    CompilerDesign,
    NeuralNetworks,
    SFormalLanguagesandAutomataTheory,
    CProgramming,
    ObjectOrientedProgrammingUsingC,
    ManagementInformationSystems,
    MSOffice,
    SystemsAnalysisandDesignMethods,
    Csharpprogramming,
    JavaScript,
    CryptographyandNetworkSecurity,
    DigitalImageProcessing,
    UNIX,
    DigitalLogicDesign,
    HTML,
    ObjectOrientedProgramming,
    PHP,
    SoftwareArchitectureandDesign,
    EmbeddedSystems,
    JavaProgramming,
    Python,
    DigitalCommunication,
    JavaSpringFramework,
    CSS,
    DiscreteMathematics,
    CyberSecurity,
    Hadoop,
    InternetofThings,
    MongoDB,
    MySQLDatabase,
    SQLServer,
    WirelessandMobileCommunications,
    DataScience,
    MATLAB,
    RProgramming,
    VisualBasic
];