import React from 'react';
import '../styles/subcatcard.css';
import { Link } from "react-router-dom";
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from 'mdb-react-ui-kit';

const SubCatCard = ({allSubCategories}) => {
  const subCatArray = Array.from(allSubCategories);

const itemStr = localStorage.getItem('isLogin');
    const item = JSON.parse(itemStr)
    const now = new Date()

    if(!itemStr || item.expiry < now.getTime()){
        localStorage.clear();
        
    }

return (
  <>
     <div className ="flex" style={{}}> 
    {subCatArray.map((curSubCat, key) => {

    return (
        
          <>  
  <div className="col-sm-4">
    <div className="catcard">
      <div className="card-body">
        <h5 className="card-title">{curSubCat.SubCatName}</h5>
        <p className="card-text subcatcard-text">Take a {curSubCat.SubCatName} online test to check your knowledge in this domain.</p>

        {
         (itemStr) ?

        <Link to={{
    pathname: `/online-mcq-test/${curSubCat.SubCatName.replaceAll(" ","-")}`,
    state: 'subcategory-test'
    
}} type="button" className="btn btn-success btn-sm subcat-btn">Attempt Now</Link>:

<MDBPopover tag="span" container= 'body' size='lg' color='danger' placement='top'
 btnChildren={<a className="btn btn-success btn-sm subcat-btn">Attempt Now</a>} dismiss>
      <MDBPopoverHeader>Login or Sign up First- It's free !</MDBPopoverHeader>
      <MDBPopoverBody>You need to <Link to='/login' type="button" class="card-login-btn">
          Login
        </Link> <span>or </span>
        <Link to='/signup' type="button" class="card-login-btn">
           Sign Up
        </Link> to Get Started.</MDBPopoverBody>
    </MDBPopover>
    }

      </div>
    </div>
  </div>

     </>   
    )
  })}
                 
   </div>   
    </>
   
)
}


export default SubCatCard
