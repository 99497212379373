import {React, useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";

const UserActivationPage = (props) => {

    const [actActivation, setActActivation] = useState(false);
    const code = props.match.params.code;
    let history = useHistory();
    var item ="";

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

     if (itemStr && item.expiry > now.getTime()) {
      history.push('/');
      window.location.reload(false);
    }

    // const goToLogin = () => {
    //     history.push({
    //         pathname: '/login',
    //         state: 'activationSuccess'
    //     });
    //     window.location.reload(false);
    // }

    const goToLogin = () => {
        console.log('called');
        history.push('/login');
        window.location.reload(false);
    }


    const goToLoginFailed = () => {
        history.push({
            pathname: '/login',
            state: 'invalidRequest'
        });
        window.location.reload(false);
    }

    const VerifyUserAccount = async () => {
        try {
           const json = JSON.stringify({activationcode : code});
           const headers = { 
               'Content-Type': 'application/json'
           };
          
           const res = await axios.post('https://compsciedu.com/services/service1.svc/VerifyAccount', json, {headers}).then(response => {
                const resMsg = response.data;
                console.log(resMsg.VerifyAccountResult);
                 if(resMsg.VerifyAccountResult == "Account activation successful."){
                    // goToLogin();
                   
                    setActActivation(true);
                    history.push({
                                pathname: '/login',
                                state: 'activationSuccess'
                            });
                    
                 }
                 else if(resMsg.VerifyAccountResult == "Invalid Request."){
                    setActActivation(false);
                    //goToLoginFailed();
                 }
           });
       } catch (error) {
           console.log(error);
       } 
      }

      useEffect(() => {
        VerifyUserAccount()
        console.log(actActivation);
        if(actActivation){
            
            history.push({
                        pathname: '/login',
                        state: 'activationSuccess'
                    });
        }
        else{
            console.log('false')
            history.push({
                        pathname: '/login',
                        state: 'invalidRequest'
                    });
        }
      },[])

  return (
  <>
  
  </>
  )
};

export default UserActivationPage;
