import React, { useEffect } from 'react';
import { useState } from "react";
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import  { Redirect } from 'react-router-dom';
import '../styles/loginpage.css';
import Logo from '../images/logo.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar';

const LoginPage = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [loginerror, setLoginError] = useState(false);
    const [activationMsg, setActivationMsg] = useState(false);
    const [inactiveMsg, setInactiveMsg] = useState(false);

    var item ="";
    const history = useHistory();

    const itemStr = localStorage.getItem('isLogin');
    item = JSON.parse(itemStr)
    const now = new Date()

    if (itemStr && item.expiry > now.getTime() && props.location.state != 'activationSuccess') {
      history.push('/');
    }

    const checkActivation = () => {
    if(props.location.state == 'activationSuccess'){
      console.log('in login page')
      setActivationMsg(true);
    }
  }

    function setUser(key, email, username, firstname, ttl) {
      const now = new Date()
      const item = {
        userid: key,
        email: email,
        username: username,
        firstname: firstname,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem('isLogin', JSON.stringify(item)) 
      return <Redirect to='/'  />
    }

      const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };

      const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required.')
            .email('Email is invalid.'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 character.')
            .required('Password is required.'),
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema)
  });

      function onSubmit(data) {

        setLoginError(false);
        props.location.state = "";

          try {
             const json = JSON.stringify({ email: data.email, pass: data.password });
 
             const headers = { 
                 'Content-Type': 'application/json'
             };
            
             const res = axios.post('https://compsciedu.com/services/service1.svc/GetUserData', json, {headers}).then(response => {
                 const userData = response.data;
                 if(userData.GetUserDataResult.LoginList[0] != undefined && userData.GetUserDataResult.LoginList[0].IsActivated == false){
                   setInactiveMsg(true);
                 }
                  
                 else if(userData.GetUserDataResult.LoginList[0] != undefined && userData.GetUserDataResult.LoginList[0].IsActivated == true){
                    {setUser(userData.GetUserDataResult.LoginList[0].UserId, userData.GetUserDataResult.LoginList[0].Email, userData.GetUserDataResult.LoginList[0].UserName, userData.GetUserDataResult.LoginList[0].FirstName, 2592000000)}
                    history.push({
                      pathname: '/',
                  });
                  
                  }
                  else{
                    setLoginError(true);
                  }
             });
         } catch (error) {
             console.log(error);
         }
      }

      useEffect( () => {
        checkActivation()
      }, [])
    
      return (
        <>

<MetaTags>
            <title>Login to your Account - Test and Rank</title>
            <meta id="meta-description" name="description" 
             />         
</MetaTags>

        <Navbar />

        <div className='BoxContainer'>
          <div className='FormContainer'>

          <div>
    <img width="120px" height="120px" src={Logo} alt="Test and Rank logo" />
    </div>

{ props.location.state == 'activationSuccess' ? 
    <div className='activation-msg'>
      <p>Congratulations !!! Your account has been successfully verified. You can Login now.</p>
    </div>
    :""
    }

{ props.location.state == 'invalidRequest' ? 
    <div className='activation-msg'>
      <p>Either this account is already verified or Invalid request.</p>
    </div>
    :""
    }    

    <div className='login-heading'>
      <h3>LOG IN</h3>
    </div>

        <div className="Login">
           <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
<div class="form-group row login-form">
    
    <div class="col-sm-12">
      <input name="email" maxLength={60} onChange={(e) => setEmail(e.target.value)} {...register('email', { required: true })} className={`form-control ${errors.email ? 'is-invalid' : ''}`}  id="inputEmail3" placeholder="Email"/>
      <div id="email-error" className="invalid-feedback">{errors.email?.message}</div>
    </div>
  </div>
  <div class="form-group row login-form">
   
    <div class="col-sm-12 pass-wrapper">
      <input name="password" maxLength={25} onChange={(e) => setPassword(e.target.value)} type={passwordShown ? "text" : "password"} {...register('password', { required: true })} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="inputPassword3" placeholder="Password" />
      <span class="p-viewer">
					<i onClick={togglePassword} class="fa fa-eye" aria-hidden="true"></i>
				</span>
        <div className="invalid-feedback">{errors.password?.message}</div>
    </div>
  </div>
 
  <div class="form-group row login-form">
    <div class="col-sm-10 login-btn-div">
      <button  type="submit" className="btn btn-primary login-btn btn-lg btn-block">Log in</button>
    </div>
  </div>
          </Form>  
          </div>

          {loginerror ?<div className="invalid-user">Invalid Email or Password. Try Again.</div> : ''}

          {inactiveMsg ?<div className="invalid-user">This account is not verified yet. Please check email used at the time of registration for activation mail.
          If you are unable to find the activation mail, <Link to="/auth/resend-activation-mail">click here </Link>click here to resend the email.
          </div> : ''}

          <div>
          <Link class="nav-link" to="/user/reset-your-password">Forgot Password?</Link>
          </div>

          <div>
          <span className='login-text'>Don't have an account?  <Link to="/signup">Sign Up</Link></span>
          </div>
          </div>
        </div>

        </>
      );
}

export default LoginPage
