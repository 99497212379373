import React from 'react';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Form from "react-bootstrap/Form";
import '../styles/loginpage.css';
import Logo from '../images/logo.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Navbar from '../components/Navbar';
import {Link} from 'react-router-dom';

const ResetPasswordPage = (props) => {

    const [changepasserror, setChangePassError] = useState(false);
    const [changepassmessage, setChangePassMessage] = useState(false);
    const [passResetVerification, setPassResetVerification] = useState(false);

    let history = useHistory();
    const itemStr = localStorage.getItem('isLogin');
    const item = JSON.parse(itemStr)
    const now = new Date()

    if(itemStr && item.expiry > now.getTime()){
      history.push('/');
      window.location.reload(false);
    }

    const code = props.match.params.code;
    
    const VerifyUserAccount = () => {
        try {
           const json = JSON.stringify({resetcode : code});
           const headers = { 
               'Content-Type': 'application/json'
           };
          
           const res = axios.post('https://compsciedu.com/services/service1.svc/PasswordResetRequest', json, {headers}).then(response => {
               const resMsg = response.data;         
                 if(resMsg.PasswordResetRequestResult == "Password reset request verification succesfull."){
                   setPassResetVerification(true);
                 }
                 else if(resMsg.PasswordResetRequestResult == "Invalid Request."){
                    setChangePassError(true);
                 } 
           });
       } catch (error) {
           console.log(error);
       } 
      }

      useEffect(() => {
        VerifyUserAccount()
      },[])

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 character.')
            .required('New Password is required.'),    
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
            .required('Confirm New Password is required.'),       
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(validationSchema)
  });

  function onSubmit(data) {
    setChangePassError(false);
    setChangePassMessage(false);
    try {
       const json = JSON.stringify({passcode: code , newpass:data.newPassword });
       const headers = { 
           'Content-Type': 'application/json'
       };
      
       const res = axios.post('https://compsciedu.com/services/service1.svc/ResetUserPassword', json, {headers}).then(response => {
           const userData = response.data;
             if(userData.ResetUserPasswordResult == "Password changed successfully."){
                reset({newPassword: '', confirmNewPassword:'' });
                setChangePassMessage(true);
             }
             else if(userData.ResetUserPasswordResult == "Invalid Request" ){
              reset({newPassword: '', confirmNewPassword:'' });
                setChangePassError(true);
             }
       });
   } catch (error) {
       console.log(error);
   } 
  }

    return (
        <>
        <Navbar />

        {passResetVerification ? 
        <div className='BoxContainer'>
          <div className='FormContainer'>

          <div>
    <img width="120px" height="120px" src={Logo} alt="Test and Rank logo" />
    </div>

    <div className='login-heading'>
      <h3>RESET PASSWORD</h3>
    </div>

        <div className="Login">
           <Form id="changepass-form" onSubmit={handleSubmit(onSubmit)} onReset={reset}>

<div class="form-group row login-form">
    <div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'> New Password:</label>
    <input name="newPassword" maxLength={25} type= "password" {...register('newPassword', { required: true })} className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`} placeholder="New Password" />
      <div className="invalid-feedback email-error">{errors.newPassword?.message}</div>
  </div>
</div>

<div class="form-group row signup-form">
  <div class="col-sm-12">
  <label className='signup-label'>Confirm New Password:</label>
    <input name="confirmNewPassword" maxLength={25} type= "password" {...register('confirmNewPassword', { required: true })} className={`form-control ${errors.confirmNewPassword ? 'is-invalid' : ''}`} placeholder="Confirm New Password" />
      <div className="invalid-feedback email-error">{errors.confirmNewPassword?.message}</div>
  </div>
</div>
    
  </div>
 
  <div class="form-group row login-form">
    <div class="col-sm-10 login-btn-div">
      <button  type="submit" className="btn btn-primary login-btn btn-lg btn-block">Reset Password</button>
    </div>
  </div>

          </Form> 
          </div>

{changepassmessage ?<div className="invalid-user">Password changed successfully. <Link to="/login">Click here</Link> to Log in and continue.</div> : ''}
     
          </div>
        </div>
  :   
"" }
{changepasserror ?<div className="invalid-user">This is Invalid request. Please start a new request.</div> : ''}
        </>
      );
};

export default ResetPasswordPage;
