import {React, useEffect, useState} from 'react';
import HomePageCard from './HomePageCard';
import axios from 'axios';
import categoryData from './CategoryData';
import '../styles/catcardcontainer.css';


const CatCardContainer = () => {
    const [allCategories, setAllCategories] = useState({});   
    
    const getAllCategories = async () =>  {
         try {
            const response = await axios.get('https://compsciedu.com/Services/service1.svc/allcategories');
            const catData = response.data.CategoryList;
            setAllCategories(catData);
        } catch (error) {
            console.log(error);
        } 
    }
    
    useEffect(() => {
       getAllCategories();  
    }, []);

    return (
        <>
        <div id="homepage-container" className="container">
       <HomePageCard allCategories = {allCategories} catData = {categoryData} />
       </div>
       </>
    )
}

export default CatCardContainer
